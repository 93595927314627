import styled from '@emotion/styled';
import { backgroundColorStyle } from '../molecules/form-controls/Control';
import React from 'react';
import MaskedInputBase from 'react-text-mask';
import { css } from '@emotion/react';
import { Flex } from 'rebass';
import InfoIcon from '../molecules/InfoIcon';
import { focusStyles } from '../styles/formControls';
import useFocusableRef from '~lib/hooks/useFocusableRef';

export const inputStates = props => css`
  &[disabled] {
    background: ${props.theme.colors.background.gray};
  }
  &:hover {
    border-color: ${props.theme.colors.borders};
  }
  ${focusStyles(props)};
`;

export const inputStyles = props => css`
  pointer-events: ${props.disabled ? 'none' : ''};
  width: 100%;
  height: auto;
  border-radius: 0;
  border: 1px solid ${props.theme.colors.borders};
  line-height: 24px;
  font-size: ${props.theme.fontSizes[2]}px;
  padding: 7px 13px;
  ${props.error ? `border-color: ${props.theme.colors.error} !important` : ''};
  ${props.disabled
    ? props.theme.colors.background.gray
    : backgroundColorStyle(props)};
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  margin-right: ${props.mr ? props.theme.space[props.mr] || props.mr : 0};
  ${inputStates(props)};
`;

const StyledInputInner = styled.input`
  ${inputStyles};
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const InputInner = React.forwardRef(({ focus, ...props }, ref) => {
  ref = useFocusableRef(ref, focus);
  return <StyledInputInner {...props} ref={ref} />;
});

const Info = styled(InfoIcon)`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  ${props => props.theme.mq.md} {
    right: -32px;
  }
`;

const InfoIconContainer = styled(Flex)`
  position: relative;
`;

export const StyledInput = React.forwardRef(
  (
    {
      innerRef,
      realNumber = false,
      onBlur,
      onChange,
      trim = false,
      disabled,
      infoIcon,
      ...props
    },
    ref
  ) => {
    const handleChange = evt => {
      if (evt.target.value === '') {
        onChange(evt);
      } else {
        onChange(realNumber ? Number(evt.target.value) : evt);
      }
    };
    const inputElement = (
      <InputInner
        disabled={disabled}
        data-lpignore="true"
        onChange={handleChange}
        onBlur={
          trim
            ? evt => {
                evt.target.value = evt.target.value.trim();
                handleChange(evt);
                onBlur(evt);
              }
            : onBlur
        }
        {...props}
        ref={innerRef}
      />
    );

    return infoIcon ? (
      <InfoIconContainer>
        {inputElement}
        <Info ml={2} tooltip={infoIcon} />
      </InfoIconContainer>
    ) : (
      inputElement
    );
  }
);

export const MaskedInput = ({ innerRef, type, mask, ...props }) => {
  return (
    <MaskedInputBase
      {...props}
      mask={mask}
      ref={innerRef}
      render={(ref, props) => (
        <InputInner data-lpignore="true" {...props} ref={ref} />
      )}
    />
  );
};
